import devAugurMessages from 'common/dist/messages/augurs.devAugur';
import layoutElementMessages from 'common/dist/messages/augurs.elements';
import commonMessages from 'common/dist/messages/common';
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FieldError,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useSelectedAugurPage } from './hooks';
import EmptyAugur from './placeholderPages/EmptyAugur';
import styles from './styles.module.scss';
import {
  AugurReport,
  AugurReportElementProps,
  AugurReportEntry,
  AugurReportType,
  AugurSettings,
  isAugurReportsElement,
  isAugurSettingsElement,
  LayoutElement,
  ModuleConfiguration,
} from './type';
import { useValidateOnChange } from './utils/augurSettings.form';
import {
  ConfigForm,
  ConfigPageElementForm,
  configToFormState,
  formStateToConfig,
  registerElementFields,
  registerPageFields,
  toFormStateElement,
  unregisterElementFields,
  unregisterPageFields,
} from './utils/config.form';
import {
  configFormToMenuCategories,
  getValidPage,
  isSelectablePageId,
  menuCategoriesToPagesFormState,
} from './utils/menuCategories';
import { transformConfigToConfigProps } from './utils/transformation';
import Button from '../../atoms/button/Button';
import parentStyles from '../../molecules/augur-layout-elements/common/styles.module.scss';
import {
  elementsToGalleryEntries,
  ElementVersions,
  findElementMeta,
} from '../../molecules/augur-layout-elements/common/utils';
import ReportParentElement from '../../molecules/augur-layout-elements/report-elements/reportParentElement/ReportParentElement';
import { reportElementMetas } from '../../molecules/augur-layout-elements/report-elements/types/meta';
import { ReportElementTypes } from '../../molecules/augur-layout-elements/report-elements/types/type';
import SettingsParentElement from '../../molecules/augur-layout-elements/settings-elements/settingsParentElement/SettingsParentElement';
import { settingsElementMetas } from '../../molecules/augur-layout-elements/settings-elements/types/meta';
import { SettingsElementTypes } from '../../molecules/augur-layout-elements/settings-elements/types/type';
import AugurMenu from '../../molecules/augur-menu/AugurMenu';
import {
  AUGUR_CATEGORY,
  AugurCategory,
  AugurMenuCategory,
} from '../../molecules/augur-menu/types';
import GridLayoutEditor from '../../molecules/grid-layout-editor/GridLayoutEditor';
import {
  GridLayoutElement,
  GridLayoutElementPayload,
  PAYLOAD_CATEGORY,
} from '../../molecules/grid-layout-editor/type';
import DevAugurSidebar from '../../organisms/dev-augur-sidebar/DevAugurSidebar';
import ErrorBoundary from '../error-boundary/ErrorBoundary';
import EditConfigurationPage from './EditConfigurationPage';
import EditReportSectionPage from './EditReportSectionPage';
import EditSettingsSectionPage from './EditSettingsSectionPage';

/**
 * Wrapper component for layout elements in Dev Augur to extract current form state
 * Does the switch between report and settings and checks the validation for each.
 * Even if the validation fails the element is rendered, so that a broken config can be fixed.
 */
export const TransformationWrapper: FC<{
  elementId: string;
  sampleReport?: AugurReport;
  interactionDisabled?: boolean;
  fullscreenDisabled?: boolean;
  isElementInvalid?: boolean;
}> = ({
  elementId,
  sampleReport,
  interactionDisabled = true,
  fullscreenDisabled,
  isElementInvalid,
}) => {
  const { getValues, formState, getFieldState } = useFormContext<ConfigForm>();
  const { isValidating: isFormValidating } = formState;

  // because RHF treats form fields valid during validation, we have to store the previous state to use during validation
  const [isStoredElementInvalid, setIsStoredElementInvalid] =
    useState<boolean>(true);
  const [storedElement, setStoredElement] = useState<ConfigPageElementForm>();

  const formValues = getValues(`elements.${elementId}`);

  // we have to provided formState as second argument here, otherwise, the invalid field will not be set correctly
  // see https://react-hook-form.com/docs/useform/getfieldstate for more info
  const { invalid: invalidConfig } = getFieldState(
    `elements.${elementId}.config`,
    formState
  );
  const { invalid: invalidDefaultAugurSettings } = getFieldState(
    `elements.${elementId}.defaultAugurSettings`,
    formState
  );
  const { invalid: invalidValidationSchema } = getFieldState(
    `elements.${elementId}.validation`,
    formState
  );
  const isFormElementInvalid =
    invalidConfig ||
    invalidDefaultAugurSettings ||
    invalidValidationSchema ||
    isElementInvalid;

  // store element form state and its validity before validation starts
  useEffect(() => {
    if (!isFormValidating) {
      setIsStoredElementInvalid(isFormElementInvalid);
      // we need to create a copy here (seems like RHF directly modifies the returned object)
      setStoredElement({ ...formValues });
    }
  }, [isFormValidating, isFormElementInvalid, formValues]);

  // show error IF (form is validated AND element is invalid) OR (form is validating and element was previously invalid)
  if (isFormElementInvalid || isStoredElementInvalid) {
    return (
      <div className={parentStyles.parentContainer}>
        <div className={parentStyles.invalid}>
          <FormattedMessage {...layoutElementMessages.elementInvalid} />
        </div>
      </div>
    );
  }

  // show stored element IF (form is validating AND element was previously valid) OR show current element in form state IF (form is validated AND element is valid)
  const element = isFormValidating ? storedElement : formValues;

  // The JSON.parse is sometimes executed even though invalidConfig is true and the error is displayed, which causes the page to crash
  let config;
  try {
    config = transformConfigToConfigProps(JSON.parse(element.config));
  } catch {
    config = {};
  }

  if (isAugurReportsElement(element)) {
    const elementMeta = findElementMeta(element.type, element.version);

    const reportEntries: AugurReportEntry[] = [];
    if (sampleReport) {
      const { reportData, ...metaData } = sampleReport;
      reportEntries.push({
        ...metaData,
        // try to extract data from sample report and use default data as fallback
        reportValue:
          reportData?.[element.reportKey] ?? elementMeta.reportDataDefault,
      });
    } else {
      // no sample report -> we use default report data and placeholder meta data
      reportEntries.push({
        type: element.type as AugurReportType,
        jobCode: 'no-job-code',
        modelCode: 'no-model-code',
        timestamp: new Date(),
        reportValue: elementMeta.reportDataDefault,
      });
    }

    const elementProps: AugurReportElementProps = {
      ...element,
      config,
      reportEntries,
    };

    return (
      <ReportParentElement
        elementProps={elementProps}
        elementMeta={elementMeta}
        interactionDisabled={interactionDisabled}
        fullscreenDisabled={fullscreenDisabled}
      />
    );
  } else if (isAugurSettingsElement(element)) {
    // RHF library seems to set validating state after the actual value of the input element.
    // Therefore, the JSON parse fails for invalid default augur settings
    let defaultAugurSettings;
    try {
      defaultAugurSettings = JSON.parse(element.defaultAugurSettings);
    } catch {
      defaultAugurSettings = {};
    }

    const elementMeta = findElementMeta(element.type, element.version);

    const elementProps = {
      ...element,
      config,
      defaultAugurSettings,
    };

    return (
      <SettingsParentElement
        elementProps={elementProps}
        elementMeta={elementMeta}
        interactionDisabled={interactionDisabled}
      />
    );
  }
  throw new Error(
    `Element is neither report nor setting. Type: ${element.type}`
  );
};

export type Props = {
  moduleConfig: ModuleConfiguration;
  augurReports: AugurReport[];
  augurSettings: AugurSettings;
  onLeaveEditMode: () => void;
  onSubmitConfig: (config: ModuleConfiguration) => void;
};

const EditAugur: FC<Props> = ({
  moduleConfig,
  augurReports,
  augurSettings,
  onLeaveEditMode,
  onSubmitConfig,
}) => {
  const history = useHistory();
  const intl = useIntl();

  const [selectedElementId, setSelectedElementId] = useState<string>();
  const { selectedPageId, selectedPageCategory } = useSelectedAugurPage();
  const [isEditingConfiguration, setIsEditingConfiguration] =
    useState<boolean>(false);
  const [isEditingReportSection, setIsEditingReportSection] =
    useState<boolean>(false);
  const [isEditingSettingsSection, setIsEditingSettingsSection] =
    useState<boolean>(false);

  // construct config form
  const initialValues = useMemo(
    () => configToFormState(moduleConfig),
    [moduleConfig]
  );
  const [registeredPages, setRegisteredPages] = useState(
    Object.values(initialValues.pages).map((page) => page.uuid)
  );
  const [registeredElements, setRegisteredElements] = useState<
    [elementId: string, pageId: string][]
  >(
    Object.values(initialValues.elements).map((element) => [
      element.uuid,
      element.pageId,
    ])
  );
  const configFormMethods = useForm<ConfigForm>({
    defaultValues: initialValues,
    mode: 'all',
  });
  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
    register,
    unregister,
    trigger,
  } = configFormMethods;
  const altIsValid = Object.keys(errors).length === 0;

  useValidateOnChange(control, trigger);

  const formValues = getValues();
  registeredPages.forEach((pageId) => {
    registerPageFields(register, formValues.pages[pageId]);
    register(`arrangements.${pageId}`);
  });
  registeredElements.forEach(([elementId]) => {
    registerElementFields(register, formValues.elements[elementId]);
  });

  // determine category of currently selected page
  const currentMenuCategory = formValues.pages[selectedPageId]?.menuCategory;
  const isReportPage = (
    [
      AUGUR_CATEGORY.LEARNING,
      AUGUR_CATEGORY.EVALUATION,
      AUGUR_CATEGORY.PREDICTION,
    ] as AugurCategory[]
  ).includes(currentMenuCategory);
  const isSettingsPage = AUGUR_CATEGORY.SETTINGS === currentMenuCategory;

  const galleryEntries = isReportPage
    ? elementsToGalleryEntries(reportElementMetas)
    : isSettingsPage
    ? elementsToGalleryEntries(settingsElementMetas)
    : [];

  // find the first report of the current page category to use as dummy data or use empty default
  const sampleReport: AugurReport = augurReports.find(
    (report) => report.type === currentMenuCategory
  );
  // find the first settings to use as dummy data
  const sampleSettings = augurSettings;

  // calculate the number of invalid pages using the form errors
  const nInvalidPages = new Set(
    Object.keys(errors.pages || {})
      .concat(Object.keys(errors.elements || {}))
      .concat(Object.keys(errors.arrangements || {}))
  ).size;

  const renderAugurMenu = () => {
    return (
      <Controller
        name={'pages'}
        control={control}
        render={({ field }) => {
          const pages = field.value;
          const categories = configFormToMenuCategories(pages, 'edit');

          // check form state for errors for each page and construct error message accordingly
          const categoriesWithErrors = categories.map((category) => {
            return {
              ...category,
              entries: category.entries.map((entry) => {
                const pageElements = registeredElements
                  .filter(([, pageId]) => pageId === entry.id)
                  .map(([elementId]) => elementId);
                const nPageElementErrors = Object.keys(
                  errors?.elements || {}
                ).filter((elementId) =>
                  pageElements.includes(elementId)
                ).length;
                const errorMessage =
                  Object.entries(errors.pages?.[entry.id] || {}).reduce(
                    (acc, [key, value]: [key: string, value: FieldError]) => {
                      return value.message
                        ? `${acc}${key}: ${value.message}\n`
                        : acc;
                    },
                    ''
                  ) +
                  (errors.arrangements?.[entry.id]
                    ? intl.formatMessage(devAugurMessages.invalidArrangement)
                    : '') +
                  (nPageElementErrors > 0
                    ? intl.formatMessage(devAugurMessages.invalidElements, {
                        nElements: nPageElementErrors,
                      })
                    : '');

                return {
                  ...entry,
                  error: errorMessage || undefined,
                };
              }),
            };
          });

          const onChangeCategories = (value: AugurMenuCategory[]) => {
            // page may have been deleted -> try to select other valid page
            if (!isSelectablePageId(value, selectedPageId)) {
              const nextPage = getValidPage(value)?.id || '';
              history.push(
                `/${selectedPageCategory}/${nextPage}${history.location.search}`
              );
            }

            const newPagesFormState = menuCategoriesToPagesFormState(value);
            field.onChange(newPagesFormState);

            // calculate added/removed pages and register/unregister form fields accordingly
            const oldPageIds = categories.flatMap((category) =>
              category.entries.map((page) => page.id)
            );
            const newPageIds = value.flatMap((category) =>
              category.entries.map((page) => page.id)
            );
            const addedPageIds = newPageIds.filter(
              (pageId) => !oldPageIds.includes(pageId)
            );
            const removedPageIds = oldPageIds.filter(
              (pageId) => !newPageIds.includes(pageId)
            );
            addedPageIds.forEach((pageId) => {
              // add new page to list of rendered pages
              setRegisteredPages((previous) => [...previous, pageId]);
              // no need to register, will happen in next render phase
            });
            removedPageIds.forEach((pageId) => {
              // remove page from list of registered pages
              setRegisteredPages((previous) =>
                previous.filter((prevId) => prevId !== pageId)
              );

              // remove elements of deleted page from registered elements
              const elementsToUnregister = registeredElements
                .filter(([, elementPageId]) => elementPageId === pageId)
                .map(([elementId]) => elementId);
              setRegisteredElements((previous) =>
                previous.filter(
                  ([elementId]) => !elementsToUnregister.includes(elementId)
                )
              );

              // need to manually delete the values, see https://github.com/orgs/react-hook-form/discussions/3884#discussioncomment-305889
              // this has to happen before the unregister as otherwise the Controllers will re-register some fields
              const pagesCopy = { ...formValues.pages };
              delete pagesCopy[pageId];
              setValue(`pages`, pagesCopy);

              const elementsCopy = { ...formValues.elements };
              elementsToUnregister.map(
                (elementId) => delete elementsCopy[elementId]
              );
              setValue(`elements`, elementsCopy);

              // unregister page to remove value from form state and disable validation
              unregisterPageFields(unregister, pages[pageId]);
              unregister(`arrangements.${pageId}`);

              // unregister elements fields to remove values and disable validation
              elementsToUnregister.map((elementId) => {
                unregisterElementFields(
                  unregister,
                  formValues.elements[elementId]
                );
              });
            });
          };

          return (
            <AugurMenu
              selectedTab={selectedPageId}
              isEditMode={true}
              onSelectEntry={(value, category) => {
                setSelectedElementId(undefined);
                history.push(`/${category}/${value}${history.location.search}`);
              }}
              categories={categoriesWithErrors}
              onChangeCategories={onChangeCategories}
            />
          );
        }}
      />
    );
  };

  const renderLayoutEditor = () => {
    if (!currentMenuCategory)
      return (
        <EmptyAugur
          headline={'No Reports and Settings Pages'}
          description={'Your Augur currently has no pages.'}
        />
      );

    // auxiliary function to add elements to elements field record after arrangement field was changed
    const addElement = (
      id: string,
      { type, version, title }: GridLayoutElementPayload
    ) => {
      const newElement = {
        uuid: id,
        type: type as ReportElementTypes | SettingsElementTypes,
        version: version as ElementVersions<
          SettingsElementTypes | ReportElementTypes
        >,
        title: title ?? type,
      } satisfies LayoutElement;
      const formElement = toFormStateElement(newElement, selectedPageId);

      // add new element to list of registered elements
      setRegisteredElements((previous) => [...previous, [id, selectedPageId]]);
      setValue(`elements.${formElement.uuid}`, formElement);
      // no need to register field, will happen in next render cycle
    };

    // auxiliary function to remove elements from elements field record after arrangement field was changed
    const removeElementById = (id: string) => {
      setRegisteredElements((previous) =>
        previous.filter(([elementId]) => elementId !== id)
      );

      // need to manually delete the values, see https://github.com/orgs/react-hook-form/discussions/3884#discussioncomment-305889
      // this has to happen before the unregister as otherwise the Controllers will re-register some fields
      const elementsCopy = { ...formValues.elements };
      delete elementsCopy[id];
      setValue(`elements`, elementsCopy);

      unregisterElementFields(unregister, formValues.elements[id]);
    };

    return (
      <Controller
        key={selectedPageId} // the key is important so the internal state of the DnDEditor is reset
        name={`arrangements.${selectedPageId}` as `arrangements.0`}
        control={control}
        render={({ field }) => {
          const arrangement = field.value ?? []; // value is undefined on page creation
          const renderedElements = arrangement.reduce((acc, layoutElement) => {
            // build an error messages if there are any invalid fields for this element
            const errorMessage = Object.entries(
              errors.elements?.[layoutElement.i] || {}
            ).reduce((acc, [key, value]: [key: string, value: FieldError]) => {
              return `${acc}${key}: ${value.message}\n`;
            }, '');

            return [
              {
                id: layoutElement.i,
                error: errorMessage,
                element: (
                  <ErrorBoundary>
                    <TransformationWrapper
                      elementId={layoutElement.i}
                      sampleReport={sampleReport}
                    />
                  </ErrorBoundary>
                ),
              },
              ...acc,
            ];
          }, [] as GridLayoutElement[]);

          return (
            <GridLayoutEditor
              arrangement={arrangement}
              elements={renderedElements}
              category={
                isReportPage
                  ? PAYLOAD_CATEGORY.REPORT
                  : isSettingsPage
                  ? PAYLOAD_CATEGORY.SETTINGS
                  : PAYLOAD_CATEGORY.NONE
              }
              viewOnly={false}
              onAddElement={addElement}
              onRemoveElement={removeElementById}
              selectedElementUuid={selectedElementId}
              onSelectElement={setSelectedElementId}
              onChangeArrangement={field.onChange}
            />
          );
        }}
      />
    );
  };

  const onSubmit: SubmitHandler<ConfigForm> = (configForm) => {
    onSubmitConfig({
      ...formStateToConfig(configForm),
      // EditAugur doesn't modify the general configuration
      generalConfiguration: moduleConfig.generalConfiguration,
    });
    onLeaveEditMode();
  };

  return (
    <FormProvider {...configFormMethods}>
      <form
        className={styles.devAugurContainer}
        onSubmit={handleSubmit(onSubmit)}
        // prevent submission on enter
        onKeyDown={(e) => {
          e.key === 'Enter' &&
            !(e.target instanceof HTMLTextAreaElement) &&
            e.preventDefault();
        }}
      >
        {/*<DevTool control={control} />*/}

        {isEditingConfiguration ? (
          <EditConfigurationPage
            selectedElementId={selectedElementId}
            sampleReport={sampleReport}
            onBack={() => setIsEditingConfiguration(false)}
          />
        ) : isEditingReportSection ? (
          <EditReportSectionPage
            selectedElementId={selectedElementId}
            sampleReport={sampleReport}
            onBack={() => setIsEditingReportSection(false)}
          />
        ) : isEditingSettingsSection ? (
          <EditSettingsSectionPage
            selectedElementId={selectedElementId}
            sampleReport={sampleReport}
            sampleSettings={sampleSettings?.settingsData}
            onBack={() => setIsEditingSettingsSection(false)}
          />
        ) : (
          <>
            <div className={styles.sideNavContainer}>
              {renderAugurMenu()}
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.editAugurButtons}
                  color={'primary'}
                  label={commonMessages.submit}
                  disabled={!altIsValid}
                  title={
                    altIsValid
                      ? undefined
                      : intl.formatMessage(devAugurMessages.invalidPages, {
                          nPages: nInvalidPages,
                        })
                  }
                  type={'submit'}
                />
                <Button
                  className={styles.editAugurButtons}
                  color={'white'}
                  label={commonMessages.cancel}
                  onClick={onLeaveEditMode}
                />
              </div>
            </div>
            <div className={styles.devAugurParent}>
              <div className={styles.contentContainer}>
                {renderLayoutEditor()}
              </div>
              <div className={styles.sidebarContainer}>
                <DevAugurSidebar
                  selectedElementId={selectedElementId}
                  setSelectedElementId={setSelectedElementId}
                  elements={galleryEntries}
                  isReportPage={isReportPage}
                  sampleReport={sampleReport?.reportData}
                  sampleSettings={sampleSettings?.settingsData}
                  onEditConfigurationButton={() =>
                    setIsEditingConfiguration(true)
                  }
                  onEditReportSectionButton={() =>
                    setIsEditingReportSection(true)
                  }
                  onEditSettingsSectionButton={() =>
                    setIsEditingSettingsSection(true)
                  }
                />
              </div>
            </div>
          </>
        )}
      </form>
    </FormProvider>
  );
};

export default EditAugur;
