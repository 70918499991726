import classNames from 'classnames';
import {
  GeneralConfigurationResources,
  K8sResources,
} from 'common/dist/types/moduleVersion';
import { OptionType } from 'common/dist/types/utils';
import {
  isValidK8sCpuSpec,
  isValidK8sMemorySpec,
  getCpuValueInMilliunits,
} from 'common/dist/utils/kubernetes';
import React, { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import styles from './styles.module.scss';
import { validateK8sResources } from './validate';
import { NodeInfo } from '../../../core/api/codeCapsules';
import {
  WB_DEF_CPU_LIM,
  WB_DEF_CPU_REQ,
  WB_DEF_MEM_LIM,
  WB_DEF_MEM_REQ,
  WB_MAX_CPU,
  WB_MAX_GPU,
} from '../../admin/users/user-details/Attributes.form';
import { Checkbox } from '../../atoms/react-hook-form-input-elements/checkbox/Checkbox';
import { DropdownSelectInput } from '../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import { IntlTextInputLine } from '../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import { AugurSettingsForm } from '../../pages/augur/utils/augurSettings.form';
import { GpuSelectOptionType } from '../../runCodeCapsuleModal/RunCodeCapsuleModal';
import { FieldInputProps } from '../augur-layout-elements/settings-elements/types/type';
import {
  extractGpuProductOptions,
  isGpuAvailable,
} from '../k8s-node-info/utils';

export type Props = {
  nodeInfo: NodeInfo;
  headerLabel?: string;
  activeCategory?: string;
  userAttributes?: Record<string, string>;
  usePriorityClasses?: boolean;
  form?: UseFormReturn<
    GeneralConfigurationResources | K8sResources | AugurSettingsForm,
    'GeneralConfiguration' | 'K8sResources' | 'AugurSettingsForm'
  >;
};

export type ErrorType = null | { [key: string]: string };
type FormPropsType =
  | Partial<FieldInputProps<GeneralConfigurationResources>>
  | Partial<FieldInputProps<K8sResources>>
  | Partial<FieldInputProps<AugurSettingsForm>>;

export const K8sResourcesSelect: FC<Props & FormPropsType> = (props) => {
  const {
    nodeInfo,
    headerLabel = 'Workbench Resources',
    userAttributes,
    form,
    activeCategory,
  } = props;
  const { control, watch, trigger, formState } = form;

  function returnKey(key: string) {
    const type = control._options.context;
    switch (type) {
      case 'K8sResources':
        return `${key}` as `${keyof K8sResources}`;
      case 'GeneralConfiguration':
        return `${activeCategory}.resources.${key}` as `${keyof GeneralConfigurationResources}.resources.${keyof K8sResources}`;
      case 'AugurSettingsForm':
        return `general.${activeCategory}.resources.${key}` as `general.${keyof GeneralConfigurationResources}.resources.${keyof K8sResources}`;
    }
  }

  const watchUseGpu = watch(returnKey('useGpu'));
  const gpuAvailable = isGpuAvailable(nodeInfo);
  const gpuOptions = extractGpuProductOptions(nodeInfo);
  const renderTextInput = (
    key: string,
    labelDefault: string,
    placeholderDefault: string,
    defaultValue: string
  ) => {
    return (
      <Controller
        //@ts-ignore FIXME-CM
        name={key}
        control={control}
        defaultValue={defaultValue}
        rules={{
          //I just need the form values
          validate: (fieldValue, formValues) => {
            const getErrors = validateK8sResources(
              formValues,
              key,
              userAttributes
            );

            return getErrors?.[key] ?? true;
          },
        }}
        render={({ field, fieldState }) => {
          const { ref, ...rest } = field; // extract ref to pass as inputRef
          return (
            <IntlTextInputLine
              label={labelDefault}
              placeholder={placeholderDefault}
              {...rest}
              {...fieldState}
              inputRef={ref}
              error={fieldState?.error?.message}
              //@ts-ignore
              value={field.value?.toString()}
              onChange={async (e) => {
                field.onChange(e);
                await trigger();
              }}
            />
          );
        }}
      />
    );
  };

  return (
    <div className={styles.k8sResourcesSelect}>
      <span className={styles.title}>{headerLabel}</span>
      <div className={styles.grid}>
        <div className={styles.col}>
          <div className={styles.inputParent}>
            {renderTextInput(
              returnKey('cpuRequest'),
              'CPU Request',
              'e.g. 1.0, 500m',
              userAttributes &&
                userAttributes[WB_DEF_CPU_REQ] &&
                userAttributes[WB_DEF_CPU_REQ].length > 0 &&
                isValidK8sCpuSpec(userAttributes[WB_DEF_CPU_REQ][0])
                ? userAttributes[WB_DEF_CPU_REQ][0]
                : undefined
            )}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.inputParent}>
            {renderTextInput(
              returnKey('cpuLimit'),
              'CPU Limit',
              'e.g. 1.0, 500m',
              userAttributes &&
                userAttributes[WB_DEF_CPU_LIM] &&
                userAttributes[WB_DEF_CPU_LIM].length > 0 &&
                isValidK8sCpuSpec(userAttributes[WB_DEF_CPU_LIM][0])
                ? userAttributes[WB_DEF_CPU_LIM][0]
                : undefined
            )}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.inputParent}>
            {renderTextInput(
              returnKey('memoryRequest'),
              'Memory Request',
              'e.g. 2Gi, 2.5Gi',
              userAttributes &&
                userAttributes[WB_DEF_MEM_REQ] &&
                userAttributes[WB_DEF_MEM_REQ].length > 0 &&
                isValidK8sMemorySpec(userAttributes[WB_DEF_MEM_REQ][0], true)
                ? userAttributes[WB_DEF_MEM_REQ][0]
                : undefined
            )}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.inputParent}>
            {renderTextInput(
              returnKey('memoryLimit'),
              'Memory Limit',
              'e.g. 4Gi, 4.5Gi',
              userAttributes &&
                userAttributes[WB_DEF_MEM_LIM] &&
                userAttributes[WB_DEF_MEM_LIM].length > 0 &&
                isValidK8sMemorySpec(userAttributes[WB_DEF_MEM_LIM][0], true)
                ? userAttributes[WB_DEF_MEM_LIM][0]
                : undefined
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className={styles.grid}>
        <div className={classNames(styles.col, styles.gpuAvailableCol)}>
          <div
            className={classNames(styles.inputParent, {
              [styles.checkboxDisabled]: !gpuAvailable,
            })}
          >
            {!gpuAvailable && (
              <span className={styles.noGpuAvailableInfo}>
                There is no node with a GPU available in the cluster
              </span>
            )}
            <Controller
              name={returnKey('useGpu')}
              control={control}
              render={({ field }) => {
                return (
                  <Checkbox
                    label='Schedule to GPU node'
                    checked={field.value as boolean}
                    onChange={() => {
                      field.onChange(!field.value);
                    }}
                    disabled={
                      !gpuAvailable || // no gpu
                      (userAttributes &&
                        (!userAttributes[WB_MAX_GPU] || // attribute not set
                          !(userAttributes[WB_MAX_GPU].length > 0) || // attribute not set
                          !(
                            isValidK8sCpuSpec(
                              userAttributes[WB_MAX_GPU][0],
                              true,
                              false
                            ) || userAttributes[WB_MAX_GPU][0] === '0'
                          ) || // attribute invalid
                          getCpuValueInMilliunits(
                            userAttributes[WB_MAX_GPU][0]
                          ) <= 0)) // limit <= 0
                    }
                  />
                );
              }}
            />
          </div>
        </div>
        {gpuAvailable && watchUseGpu && (
          <>
            <div className={styles.col}>
              <div className={styles.inputParent}>
                {renderTextInput(
                  returnKey('gpuLimit'),
                  'Optional: GPU Limit',
                  'e.g. 1, 2',
                  undefined
                )}
              </div>
            </div>
            <div className={styles.col}>
              <Controller
                name={returnKey('gpuProduct')}
                control={control}
                rules={{
                  //I just need the form values
                  validate: (fieldValue, formValues) => {
                    const getErrors = validateK8sResources(
                      formValues,
                      returnKey('gpuProduct')
                    );
                    return getErrors?.[`${returnKey('gpuProduct')}`] ?? true;
                  },
                }}
                render={({ field }) => (
                  <DropdownSelectInput
                    id={'gpuProduct'}
                    name={'gpuProduct'}
                    label={'Optional: Select the GPU type'}
                    placeholder={'No GPU type selected'}
                    value={gpuOptions.find(
                      (o) => o?.value?.model === field.value
                    )}
                    onChange={(option: GpuSelectOptionType) =>
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                      field.onChange(option?.value)
                    }
                    isLoading={false}
                    options={gpuOptions}
                    isClearable
                  />
                )}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
