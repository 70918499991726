import React, { FC, ReactNode } from 'react';
import styles from './styles.module.scss';
import { TransformationWrapper } from './EditAugur';
import Button from '../../atoms/button/Button';
import { AugurReport } from './type';
import classNames from 'classnames';

export type SplitViewElementEditorProps = {
  selectedElementId: string;
  sampleReport: AugurReport;
  rightPanelContent: ReactNode;
  onBack: () => void;
  interactionDisabled?: boolean;
  fullscreenDisabled?: boolean;
  isElementInvalid?: boolean;
  title?: string;
  leftPanelText?: string;
};

const SplitViewElementEditor: FC<SplitViewElementEditorProps> = ({
  selectedElementId,
  sampleReport,
  rightPanelContent,
  onBack,
  interactionDisabled = false,
  fullscreenDisabled,
  isElementInvalid,
  title = 'Element Editor',
  leftPanelText,
}) => {
  return (
    <div className={styles.editConfigContainer}>
      <div className={styles.header}>
        <h2>{title}</h2>
      </div>
      <div className={styles.body}>
        <div className={styles.leftPanel}>
          <div
            className={classNames(styles.element, {
              [styles.interactionDisabled]: interactionDisabled,
            })}
          >
            <TransformationWrapper
              elementId={selectedElementId}
              sampleReport={sampleReport}
              interactionDisabled={interactionDisabled}
              isElementInvalid={isElementInvalid}
              fullscreenDisabled={fullscreenDisabled}
            />
          </div>
          {leftPanelText && (
            <span
              className={classNames(styles.lightTextItalic, styles.largeFont)}
            >
              {leftPanelText}
            </span>
          )}
        </div>
        <div className={styles.rightPanel}>{rightPanelContent}</div>
      </div>
      <div className={styles.footer}>
        <Button color='primary' label='Close' onClick={onBack} />
      </div>
    </div>
  );
};

export default SplitViewElementEditor;
