import React, { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextInputArea } from '../../atoms/react-hook-form-input-elements/text-input-area/TextInputArea';
import { AugurReport } from './type';
import { findElementMeta } from '../../molecules/augur-layout-elements/common/utils';
import { ConfigForm } from './utils/config.form';
import SplitViewElementEditor from './SplitViewElementEditor';
import IntlTextInputLine from '../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import { LuFileJson } from 'react-icons/lu';
import Modal from '../../organisms/modal/Modal';
import { AugurSettingsElementMeta } from '../../molecules/augur-layout-elements/settings-elements/types/meta';
import styles from './styles.module.scss';

export type EditSettingsSectionProps = {
  selectedElementId: string;
  sampleReport: AugurReport;
  sampleSettings: Record<string, unknown>;
  onBack: () => void;
};

const EditSettingsSectionPage: FC<EditSettingsSectionProps> = ({
  selectedElementId,
  sampleReport,
  sampleSettings,
  onBack,
}) => {
  const { control, getValues } = useFormContext<ConfigForm>();

  const element = getValues(`elements.${selectedElementId}`);
  const elementMeta = findElementMeta(element.type, element.version);

  const [showModal, setShowModal] = useState(false);

  const rightPanelContent = (
    <>
      {showModal && (
        <Modal
          show={showModal}
          hideModal={() => setShowModal(false)}
          headline={{
            id: 'no-id',
            defaultMessage: 'Current Augur Settings file',
          }}
        >
          <div className={styles.modalTextArea}>
            <TextInputArea
              label={'Augur Settings'}
              amountRows={24}
              disabled={true}
              value={
                JSON.stringify(sampleSettings, null, 2) ??
                'No settings available.'
              }
            />
          </div>
        </Modal>
      )}

      <div>
        <Controller
          name={`elements.${selectedElementId}.settingsKey`}
          control={control}
          render={({ field, fieldState }) => {
            const { ref, ...rest } = field; // extract ref to pass as inputRef
            return (
              <IntlTextInputLine
                label={'Augur Settings Key'}
                placeholder={'Type an identifier here'}
                {...rest}
                {...fieldState}
                isTouched={true}
                inputRef={ref}
                error={fieldState.error?.message}
              />
            );
          }}
        />
        <div
          className={styles.showFileContainer}
          onClick={() => setShowModal(true)}
        >
          <LuFileJson className={styles.showFileIcon} size={13} />
          <span className={styles.showFileText}>
            Show current Augur Settings file
          </span>
        </div>
      </div>

      <Controller
        name={`elements.${selectedElementId}.defaultAugurSettings`}
        control={control}
        render={({ field, fieldState }) => {
          const { ref, ...rest } = field;
          return (
            <TextInputArea
              label={'Default Augur Settings'}
              placeholder={'Please enter a valid default json object'}
              {...rest}
              {...fieldState}
              isTouched={true}
              inputRef={ref}
              error={fieldState.error?.message}
              amountRows={10}
            />
          );
        }}
      />

      <TextInputArea
        label={'Augur Settings Type'}
        disabled={true}
        value={(elementMeta as AugurSettingsElementMeta).augurSettingsType}
        amountRows={5}
      />
    </>
  );

  return (
    <SplitViewElementEditor
      selectedElementId={selectedElementId}
      sampleReport={sampleReport}
      rightPanelContent={rightPanelContent}
      onBack={onBack}
      interactionDisabled={true}
      title='Edit Default Augur Settings'
    />
  );
};

export default EditSettingsSectionPage;
