import classNames from 'classnames';
import React, { FC } from 'react';
import { FiFile } from 'react-icons/fi';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import FileActions from './FileActions';
import styles from './styles.module.scss';
import { S3Object } from '../../../../../store/dataManagement/state.types';
import { FormattedDateTime } from '../../../../atoms/formatted-date-time/FormattedDateTime';
import { dataManagementRoutes } from '../../../routes';
import { styledFileSize } from '../../../util';

const DirectoryElement: FC<
  {
    dataSourceCode: string;
    bucket: string;
    element: S3Object;
    showRenameModal: (renameFile: string, renamePath: string) => void;
    showDeleteModal: (deletePath: string) => void;
  } & RouteComponentProps
> = (props) => {
  const {
    dataSourceCode,
    bucket,
    element,
    history,
    showRenameModal,
    showDeleteModal,
  } = props;

  const linkTo = `${
    dataManagementRoutes.basePath
  }/s3/${dataSourceCode}/bucket/${bucket}/preview-table/${element.path.substring(
    1
  )}`;

  return (
    <tr
      onClick={() => history.push(linkTo)}
      className={classNames(styles.row, styles.rowTable)}
    >
      <td className={styles.colIcon}>
        <FiFile size={'16px'} />
      </td>
      <td className={styles.colName} data-testid={element.name}>
        {element.name}
      </td>
      <td className={styles.colCreatedAt}>
        <FormattedDateTime date={new Date(element.lastModified * 1000)} />
      </td>
      <td className={styles.colSize}>{styledFileSize(element.size)}</td>
      <td className={styles.colActions}>
        <FileActions
          element={element}
          dataSourceCode={dataSourceCode}
          bucket={bucket}
          showRenameModal={showRenameModal}
          showDeleteModal={showDeleteModal}
        />
      </td>
    </tr>
  );
};

export default withRouter(DirectoryElement);
